<template>
  <button
    v-on="$listeners"
    :id="`${filterId}Toggler`"
    :aria-controls="filterId"
    :aria-expanded="isFilterOpened ? 'true' : 'false'"
    aria-label="Toggle data filter"
    class="app-data-filter-btn-toggler"
    :class="{ 'app-data-filter-btn-toggler--show-always': showAlways }"
    :data-aos="appearingWithAnimation ? 'fade-up' : undefined"
  >
    Filters
  </button>
</template>

<script>
export default {
  name: 'AppDataFilterOpenButton',
  props: {
    appearingWithAnimation: {
      type: Boolean,
      default: true,
    },
    filterId: {
      type: String,
      default: 'dataFilter',
    },
    isFilterOpened: {
      type: Boolean,
      default: false,
    },
    showAlways: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
