import { AxiosError, AxiosResponse } from 'axios';
import apiProtectedRequest from '@/api/apiProtectedRequest';

const exportToPDF = (url: string, title: string, params = {}) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    apiProtectedRequest({
      url,
      method: 'get',
      data: { ...params },
      responseType: 'blob',
    })
      .then((res: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${title}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        resolve(res);
      })
      .catch(async (err: AxiosError) => {
        let error = { data: 'Error' };
        if (err.response?.data) {
          const data = await (err.response?.data as Blob).text();
          error = {
            ...err.response,
            data: JSON.parse(data),
          };
        }
        reject(error);
      });
  });
};

export function exportArtGuideToPDF({ id, title, params = {} }: any) {
  return exportToPDF(`art-guides/${id}/export/download`, `${title} - Art Guide`, params);
}

export function exportDestinationMustSeeToPDF({ id, title, params = {} }: any) {
  return exportToPDF(`destinations/${id}/must-see/export/download`, `Must see in ${title} - The Cultivist`, params);
}
