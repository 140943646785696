<template>
  <div class="container destination-must-see">
    <template v-if="mustSee.length">
      <div class="destination-must-see__controls" data-aos="fade-up">
        <AppDataFilterOpenButton
          :appearing-with-animation="false"
          class="destination-must-see__controls-filter"
          show-always
          @click="$emit('open-filter')"
        />
        <button class="destination-must-see__controls-pdf-export" @click="exportToPdf">
          <TheDownloadIcon height="24" />
        </button>
      </div>

      <div class="destination-must-see__entities">
        <DestinationMustSeeEntity
          v-for="entity in mustSee"
          :key="`${entity.__typename}_${entity.id}`"
          :entity="entity"
          :favorite-changing="favoriteChanging"
          :is-mobile="isMobileScreen"
          @toggle-favorite="toggleFavoriteState"
        />
      </div>

      <div class="make-request">
        <RouterLink class="btn make-request__btn border-btn" data-aos="fade-up" :to="seePartnerMuseumsRoute"
          >See partner museums</RouterLink
        >
        <button
          v-if="isMuseumMemberRole"
          class="btn make-request__btn border-btn"
          data-aos="fade-up"
          @click="showMuseumModal = true"
        >
          Request access
        </button>
        <button v-else class="btn make-request__btn border-btn" data-aos="fade-up" @click="showRequestForm = true">
          Request access
        </button>
      </div>
    </template>

    <DestinationTabNoResults :is-loading="loaderState" :is-no-results="!mustSee.length" />

    <CollapseTransition :duration="200" @after-enter="afterRequestFormShowed">
      <section v-if="showRequestForm" class="app-request-form">
        <header class="app-request-form__header">
          <h4 ref="appRequestForm" class="app-request-form__header-title">Subject: {{ formRequestSubject }}</h4>
        </header>
        <div class="app-request-form__message-wrapper">
          <textarea
            v-model.trim="formRequestMessage"
            class="app-request-form__message form-control"
            :class="{ 'app-request-form__message--invalid': requestFormError }"
            placeholder="Start typing..."
            @input="onChangeRequestFormMsg"
          />
          <span v-show="requestFormError" class="app-request-form__error-msg text-danger">{{ requestFormError }}</span>
          <button class="app-request-form__btn-close" title="Close" @click="showRequestForm = false">
            <TheCrossIcon />
          </button>
          <div v-show="sendingRequestForm" class="app-request-form__loader">
            <AppLoader :border-width="2" :size="24" />
          </div>
        </div>

        <button
          class="btn border-btn app-request-form__btn-submit"
          :disabled="sendingRequestForm"
          @click="sendRequestFormMsg"
        >
          Send
        </button>
      </section>
    </CollapseTransition>

    <UpgradeFromEnthusiastMsgModal v-model="showMuseumModal" />
  </div>
</template>

<script>
import { exportDestinationMustSeeToPDF } from '@/api/exportToFile';
import { navigatorCurrentPosition } from '@/helpers/GeolocationHelper';
import DestinationMustSeeDataFilterHelper from '@/helpers/data-filter/DestinationMustSeeDataFilterHelper';
import ArtSpaceDataFilterHelper from '@/helpers/data-filter/ArtSpaceDataFilterHelper';

import entitiesMustSeeInDestinationQuery from '@/graphql/destination/EntitiesMustSeeInDestination.query.gql';
import addToUserFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';
import memberRequestAccessMutation from '@/graphql/me/MakeMemberRequestAccess.mutation.gql';

import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import DestinationMustSeeEntity from '@/components/destination/DestinationMustSeeEntity';
import UpgradeFromEnthusiastMsgModal from '@/components/modals/UpgradeFromEnthusiastMsgModal';
import CollapseTransition from '@/components/transitions/CollapseTransition';
import TheDownloadIcon from '@/components/icons/TheDownloadIcon.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';
import DestinationTabNoResults from '@/components/DestinationTabNoResults.vue';

export default {
  name: 'DestinationMustSeeTab',
  components: {
    DestinationTabNoResults,
    TheCrossIcon,
    TheDownloadIcon,
    CollapseTransition,
    AppDataFilterOpenButton,
    DestinationMustSeeEntity,
    UpgradeFromEnthusiastMsgModal,
  },
  props: {
    destination: {
      type: Object,
      required: true,
    },
    filterValues: {
      type: Object,
      default: () => ({}),
    },
    filterValuesFromRoute: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mustSee: [],
      showMuseumModal: false,
      favoriteChanging: false,
      showRequestForm: false,
      sendingRequestForm: false,
      formRequestMessage: '',
      requestFormError: '',
    };
  },
  computed: {
    filterQueryVariables() {
      return DestinationMustSeeDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    formRequestSubject() {
      return `Must see in ${this.destination.title} | Access request`;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    isMuseumMemberRole() {
      return !!this.$store.state.museumRole;
    },
    loaderState() {
      return !!this.$store.state.loader;
    },
    seePartnerMuseumsRoute() {
      return {
        name: 'art-spaces',
        query: ArtSpaceDataFilterHelper.setFilterValuesToRouteQuery({
          ...ArtSpaceDataFilterHelper.setDestinationsToValuesManually([this.destination.slug]),
          ...ArtSpaceDataFilterHelper.setShowPartnerMuseumsToValuesManually(true),
        }),
      };
    },
  },
  watch: {
    filterValues: {
      handler(newValues, oldValues) {
        if (Object.keys(oldValues).length) {
          this.$emit('update-route', DestinationMustSeeDataFilterHelper.setFilterValuesToRouteQuery(newValues));
        }
      },
      deep: true,
    },
    filterValuesFromRoute: {
      handler(newValues, oldValues) {
        if (oldValues) {
          this.$emit('set-filter', {
            values: DestinationMustSeeDataFilterHelper.getFilterValuesFromRouteQuery(newValues),
          });
        }
      },
      deep: true,
    },
  },
  created() {
    navigatorCurrentPosition(
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', false);
        this.initFilter();
      },
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', true);
        this.initFilter();
      }
    );

    this.setLoader(true);
    this.initFilter();

    this.$root.$on('openPDF', this.exportToPdf);
  },
  beforeDestroy() {
    this.$root.$off('openPDF');
  },
  apollo: {
    entitiesMustSeeInDestination: {
      query: entitiesMustSeeInDestinationQuery,
      fetchPolicy: 'no-cache',
      skip() {
        return !Object.keys(this.filterValues).length;
      },
      variables() {
        return {
          id: this.destination.id,
          ...this.filterQueryVariables,
        };
      },
      result({ data: { entitiesMustSeeInDestination = [] } }) {
        this.mustSee = entitiesMustSeeInDestination;
        this.setLoader(false);
      },
    },
  },
  methods: {
    initFilter() {
      this.$emit('set-filter', {
        groups: DestinationMustSeeDataFilterHelper.prepareFilterGroups(),
        values: DestinationMustSeeDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query),
      });
    },
    setLoader(state) {
      this.$store.dispatch('setLoader', !!state);
    },
    afterRequestFormShowed() {
      const appRequestForm = this.$refs.appRequestForm;
      if (appRequestForm instanceof HTMLElement) {
        appRequestForm.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    },
    onChangeRequestFormMsg() {
      if (this.formRequestMessage) {
        this.requestFormError = '';
      }
    },
    sendRequestFormMsg() {
      if (this.sendingRequestForm) {
        return;
      }
      if (!this.formRequestMessage) {
        this.requestFormError = 'Please, enter the message.';
        return;
      }

      this.sendingRequestForm = true;

      this.$apollo
        .mutate({
          mutation: memberRequestAccessMutation,
          variables: {
            subject: this.formRequestSubject,
            message: this.formRequestMessage,
          },
        })
        .then((res) => {
          this.sendingRequestForm = false;

          if (res.data.status) {
            this.formRequestMessage = '';
            this.$toast.success('Your message was sent successfully.');
          } else {
            this.$toast.error('Server error: unable to send your message.');
          }
        });
    },
    async toggleFavoriteState(entity) {
      this.favoriteChanging = true;

      const favoriteResult = entity.is_favorite_by_current_user
        ? await this.removeFromFavorites(entity)
        : await this.addToFavorites(entity);

      this.favoriteChanging = false;

      if (favoriteResult !== null) {
        const idx = this.mustSee.findIndex((ent) => ent.id === entity.id && ent.__typename === entity.__typename);
        this.mustSee.splice(idx, 1, {
          ...entity,
          is_favorite_by_current_user: favoriteResult,
        });
      }
    },
    async addToFavorites(entity) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: addToUserFavoritesMutation,
          variables: {
            entities: [{ id: entity.id, type: entity.__typename }],
          },
        });

        return data.addToUserFavorites;
      } catch (e) {
        return null;
      }
    },
    async removeFromFavorites(entity) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: deleteFromUserFavoritesMutation,
          variables: {
            entities: [{ id: entity.id, type: entity.__typename }],
          },
        });

        return !data.deleteFromUserFavorites;
      } catch (e) {
        return null;
      }
    },
    exportToPdf() {
      this.setLoader(true);

      exportDestinationMustSeeToPDF({
        id: this.destination.id,
        title: this.destination.title,
        params: this.filterQueryVariables,
      })
        .catch(() => {
          this.$toast.error(
            'Unfortunately, you could not download the PDF file. Please contact with the administrator.'
          );
        })
        .finally(() => {
          this.setLoader(false);
        });
    },
  },
};
</script>
