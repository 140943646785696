<template>
  <RouterLink v-if="entityRoute" :to="entityRoute">
    <slot />
  </RouterLink>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DestinationMustSeeEntityPageLink',
  props: {
    entityRoute: {
      type: Object,
      default: null,
    },
  },
};
</script>
