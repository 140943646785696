import { makeRouteQueryFromData, parseRouteQuery } from '@/helpers/routerHelper';
import { getMonthName } from '@/helpers/dateTimeHelper';
import { FILTER_TYPE_DATES, FILTER_TYPE_SORT } from '@/helpers/data-filter/DataFilterTypes';
import { getCurrentGeoPosition } from '@/helpers/GeolocationHelper';

/**
 * @typedef {Object} FilterGroup
 * @property {string} type
 * @property {string} itemsType
 * @property {string|undefined} title
 * @property {Array} items
 */

/**
 * @typedef {Object} FilterGroupItem
 * @property {number|string} id
 * @property {string} title
 */

const isNotEmptyArray = (data) => {
  return Array.isArray(data) && data.length;
};

const getDatesValuesFromRouteQuery = (dates = []) => {
  return dates
    .map((date) => {
      let [year, month] = date.split('-');

      year = +year;
      month = +month;

      if (!year || !month || month < 1 || month > 12) {
        return null;
      }
      return { year, month, _filter: `${year}_${month}` };
    })
    .filter((v) => v);
};

const getSortValuesFromRouteQuery = (column, order) => {
  if (column === null || typeof column !== 'string') {
    return null;
  }
  const result = { column, _filter: column };
  if (order === 'desc') {
    result.order = order;
    result._filter += `_${order}`;
  }
  return result;
};

const transformOutputDateItems = (items = []) => {
  return items.map((item) => ({ year: item.year, month: item.month }));
};

export default class DataFilterHelper {
  defaultValues = {};
  validValuesTypes = {
    array: [],
    boolean: [],
    number: [],
    string: [],
  };

  castsToRoute = {};

  get castsFromRoute() {
    return Object.keys(this.castsToRoute).reduce((obj, key) => {
      obj[this.castsToRoute[key]] = key;
      return obj;
    }, {});
  }

  static create() {
    return new this();
  }

  addDefaultGroupItems({ group, itemsType, title }) {
    if (!group || !itemsType || !isNotEmptyArray(group)) {
      return;
    }

    const items = group.map((v) => {
      const vTitle = v.parent && v.parent.title ? `${v.title} (${v.parent.title})` : v.title;
      const value = v.slug ? v.slug : !isNaN(v.id) ? Number(v.id) : v.id;
      return { id: v.id, title: vTitle, value };
    });
    title = title || itemsType;

    return {
      type: 'checkbox',
      itemsType,
      title,
      items,
    };
  }

  addDateGroupItems({ group, itemsType, title }) {
    if (!itemsType || !isNotEmptyArray(group)) {
      return;
    }

    const items = group.map(({ year, month }) => {
      return {
        id: `${year}_${month}`,
        title: year + ' ' + getMonthName({ year, month }),
        value: { year, month, _filter: `${year}_${month}` },
      };
    });
    title = title || itemsType;

    return {
      type: 'checkbox',
      itemsType,
      title,
      items,
    };
  }

  addRadioGroupItems({ itemsType, items }) {
    items = items || [];
    items = items.map((item) => {
      const transformedItem = { ...item };
      if (transformedItem.value && typeof transformedItem.value === 'object') {
        transformedItem.value._filter = Object.values(transformedItem.value).join('_');
      } else if (transformedItem.value === undefined && transformedItem.id !== undefined) {
        transformedItem.value = transformedItem.id;
      }
      return transformedItem;
    });

    return {
      type: 'radio',
      itemsType,
      items,
    };
  }

  prepareFilterValuesToQueryVariables(values = {}, fallback = {}) {
    const transformed = { ...values, ...fallback };

    for (const itemsType in transformed) {
      if (itemsType === FILTER_TYPE_DATES) {
        transformed[FILTER_TYPE_DATES] = transformOutputDateItems(transformed.dates);
        continue;
      }
      if (itemsType === FILTER_TYPE_SORT) {
        const sortValues = transformed[FILTER_TYPE_SORT];

        if (!sortValues) {
          transformed[FILTER_TYPE_SORT] = null;
          continue;
        }
        if (sortValues.column === 'near_start_date') {
          transformed[FILTER_TYPE_SORT] = {
            nearStartDate: true,
          };
          continue;
        }
        if (sortValues.column === 'distance') {
          transformed[FILTER_TYPE_SORT] = {
            distancePosition: getCurrentGeoPosition(),
          };
          continue;
        }
        transformed[FILTER_TYPE_SORT] = {
          column: sortValues.column,
          order: sortValues.order || 'asc',
        };
      }
    }

    return transformed;
  }

  makeValuesCasting(values = {}, reverse = true) {
    if (!Object.keys(this.castsToRoute).length) {
      return values;
    }

    const casts = reverse ? this.castsFromRoute : this.castsToRoute;
    const castedValues = {};

    for (const prop in values) {
      const key = casts[prop] || prop;
      castedValues[key] = values[prop];
    }
    return castedValues;
  }

  getFilterValuesFromRouteQuery(values = {}) {
    const result = { ...this.defaultValues };
    const preparedValues = this.makeValuesCasting({ ...parseRouteQuery(values, this.validValuesTypes) });

    const validTypes = {
      array: [],
      boolean: [],
      number: [],
      string: [],
      ...this.validValuesTypes,
    };

    for (const name in result) {
      if (
        (Array.isArray(result[name]) && !Array.isArray(preparedValues[name])) ||
        (validTypes.boolean.includes(name) && typeof preparedValues[name] !== 'boolean')
      ) {
        continue;
      }
      result[name] = preparedValues[name] !== undefined ? preparedValues[name] : null;
    }
    if (preparedValues.dates) {
      result.dates = getDatesValuesFromRouteQuery(preparedValues.dates);
    }
    if (preparedValues.sort) {
      result.sort = getSortValuesFromRouteQuery(preparedValues.sort, preparedValues.sort_order);
    }

    return result;
  }

  setFilterValuesToRouteQuery(values = {}) {
    const params = {};
    let sortOrder = '';

    for (const name in values) {
      let value = values[name];

      if (name === FILTER_TYPE_DATES) {
        value = values.dates.map((d) => {
          const month = d.month < 10 ? `0${d.month}` : d.month;
          return `${d.year}-${month}`;
        });
      } else if (name === FILTER_TYPE_SORT) {
        if (value !== null) {
          sortOrder = value.order;
          value = value.column;
        }
      }

      params[name] = value;
      if (sortOrder === 'desc') {
        params.sort_order = sortOrder;
      }
    }

    return makeRouteQueryFromData(this.makeValuesCasting(params, false));
  }

  isAllValuesEmpty(values) {
    let numOfEmptyFields = 0;

    for (const field in values) {
      if (values[field] === undefined || values[field] === null || values[field] === '') {
        numOfEmptyFields++;
        continue;
      }
      if (Array.isArray(values[field]) && !values[field].length) {
        numOfEmptyFields++;
        continue;
      }
      if (typeof values[field] === 'object' && values[field] !== null && this.isAllValuesEmpty(values[field])) {
        numOfEmptyFields++;
      }
    }

    return numOfEmptyFields === Object.keys(values).length;
  }

  /**
   * Implementation required
   */
  prepareFilterGroups() {
    throw new Error('You must to implement the method prepareFilterGroups!');
  }
}
