<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2907 6.77164L8.00005 13.6L1.7094 6.77164C0.453855 5.40877 0.504392 3.31307 1.82421 2.01068C3.29786 0.556478 5.72528 0.689146 7.02719 2.29504L8.00005 3.49507L8.97291 2.29504C10.2748 0.689146 12.7022 0.556478 14.1759 2.01068C15.4957 3.31307 15.5462 5.40877 14.2907 6.77164Z"
      :fill="fillColor"
      :stroke="strokeColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="0.7"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheHeartIcon',
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fillColor() {
      return this.filled ? '#5b5d62' : '#ffffff';
    },
    strokeColor() {
      return this.filled ? '#5b5d62' : '#363636';
    },
  },
};
</script>
